import React, { useState, useEffect } from "react";
import IosCreate from "react-ionicons/lib/IosCreateOutline";
import IosContacts from "react-ionicons/lib/IosContactsOutline";
import IosSettings from "react-ionicons/lib/IosSettingsOutline";
import MdInformationCircle from "react-ionicons/lib/MdInformationCircle";
import "./style.scss";
import Header from "../../components/header";
import { connect } from "react-redux";
import NoticeButton from "../../components/NoticeButton";
import Background from "../../components/background";
import getUser from "../../helpers/users/getUser";
import Emoji from "../../components/emoji";
import getGame from "../../helpers/game/getGame";
function Homepage(props) {
  const { create, invites, settings, aboutUs } = global.lang;
  const { lang } = props;

  const username = props.cookies.get("username");
  const token = props.cookies.get("token");

  if (!username || !token) {
    window.location.href = "/login";
  }

  if (!props.user._id) {
    console.log(props.user);
    getUser(username, token).then(value => props.setUser(value.user));
  }

  return (
    <div className="invites">
      <Background />
      <Header value={"QTime"} />
      <div className="invites-container">
        <div className="score-board">
          <div className="score-board-title">
            <p>{invites[lang]}</p>
          </div>
          <div className="score-board-players">
            {props.user.invites
              ? props.user.invites.map(value => Invite(username, token, value))
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

function Invite(username, token, id) {
  const [value, setValue] = useState({});
  useEffect(
    () =>
      getGame(username, token, id).then(value => {
        const game = value.game;
        const numberOfPlayers = game.players.length;
        const numberOfInvited = game.invited.length;
        console.log(game);
        setValue({
          admin: game.admin[0],
          numberOfPlayers: `${numberOfPlayers}/${numberOfInvited +
            numberOfPlayers}`
        });
      }),
    []
  );
  function onClick(id) {
    window.location.href = "/lobby/" + id;
  }
  return value ? (
    <div className="invites-board" onClick={() => onClick(id)}>
      <div className="invites-board-icon">
        <Emoji symbol="✉️" />
      </div>
      <div className="invites-board-name">
        <p>{value.admin}</p>
      </div>
      <div className="invites-board-value">
        <p>{value.numberOfPlayers}</p>
      </div>
    </div>
  ) : (
    <div>loading</div>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUser: item => {
      dispatch({ type: "SET_USER", payload: item });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Homepage);
