import React from "react";

import Spinner from "react-bootstrap/Spinner";
import "./style.scss";

const LoadingPanel = props => {
  const style = {
    width: props.width + "vh",
    height: props.height + "vh"
  };

  return (
    <div style={style} className="loading-panel">
      <div className="loading-panel-spinner">
        <Spinner animation="border" role="status" />
      </div>
      <div style={style} className="loading-title">
        <h1 className="loading-text">{props.title}</h1>
      </div>
    </div>
  );
};
export default LoadingPanel;
