import React from "react";
import "./style.scss";
import MdPerson from "react-ionicons/lib/MdPerson";

const PlayerView = props => {
  return (
    <div className="player-view" onClick={props.onClick}>
      <div className="player-view-cons">
        {props.icons ? props.icons : <MdPerson color="#ff000000" />}
      </div>
      <div
        className="player-view-circle"
        style={{
          backgroundColor: props.color
        }}
      >
        <h1>{props.name}</h1>
      </div>
    </div>
  );
};
export default PlayerView;
