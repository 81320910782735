import { url } from "../../environment/paths";
const createGame = (username, token, gameID) => {
  const header = {
    "Content-Type": "application/json"
  };

  const body = {
    username,
    token,
    gameID
  };

  return fetch(
    `${url}/game/get`,
    {
      method: "POST",
      header: header,
      body: JSON.stringify(body)
    },
    1000
  )
    .then(res => res.json())
    .catch(err => false);
};

export default createGame;
