import React from "react";
import { Link } from "react-router-dom";
import Router from "./Router";
//const Navigation = (props) => <Link to="/login"><button>test</button></Link>
import { withCookies } from "react-cookie";
import Header from "./components/header";
function App(props) {
  return (
    <div>
      <Router cookies={props.cookies} />
    </div>
  );
}

export default withCookies(App);
