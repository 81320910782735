import { createStore, combineReducers } from "redux";
import langReducer from "../reducers/langReducer";
import userReducer from "../reducers/userReducer";
import gameReducer from "../reducers/gameReducer";
const rootReducer = combineReducers({
  lang: langReducer,
  game: gameReducer,
  user: userReducer
});
const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
