import React from "react";
import "./style.scss";
import MdPerson from "react-ionicons/lib/MdPerson";
import IosBrush from "react-ionicons/lib/IosBrush";
import IosFlask from "react-ionicons/lib/IosFlask";
import IosFlame from "react-ionicons/lib/IosFlame";
import NoticeButton from "../NoticeButton";

const LabIcon = props => {
  const bubbles = [<div className="bubbles" />, <div className="bubbles-2" />];

  return (
    <div className="lab-icon-whole">
      <div className="lab-icon">
        <div className="lab-icon-animation">
          <div className="lab-fire">{bubbles}</div>
          <div className="lab-flask">
            <IosFlask {...props} color="#5050aa" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LabIcon;
