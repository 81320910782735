import React from "react";
import "./style.scss";
import LangSelector from "../langSelector";
import Logo from "../logo";
import Options from "../sidebar";
export default function index(props) {
  return (
    <div className="header-main">
      <div className="header-logo">
        <Logo />
      </div>

      <div className="header-title">
        <h1>{props.value}</h1>
      </div>
      <Options {...props} />

      <div className="header-lang-selector">
        <LangSelector />
      </div>
    </div>
  );
}
