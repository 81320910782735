import React, { useState, useEffect } from "react";
import IosCreate from "react-ionicons/lib/IosCreateOutline";
import IosContacts from "react-ionicons/lib/IosContactsOutline";
import IosSettings from "react-ionicons/lib/IosSettingsOutline";
import MdInformationCircle from "react-ionicons/lib/MdInformationCircle";
import "./style.scss";
import Header from "../../components/header";
import { connect } from "react-redux";
import NoticeButton from "../../components/NoticeButton";
import Background from "../../components/background";
import getUser from "../../helpers/users/getUser";
import Emoji from "../../components/emoji";
import getGame from "../../helpers/game/getGame";
import SittingRobot from "./sittingRobot";
function Homepage(props) {
  const { create, invites, settings, aboutUs, noPageFound } = global.lang;
  const { lang } = props;

  return (
    <div className="invites">
      <Background />
      <Header value={"QTime"} />
      <div className="no-page-title">
        <p>404</p>
      </div>
      <div
        className="no-page-go-back"
        onClick={() => (window.location.href = "/home")}
      >
        <Emoji symbol="↩️" />
      </div>

      <div className="no-page-description">
        <p>{noPageFound[lang]}</p>
      </div>

      <div className="no-page-robot">
        <div className="no-page-robot-container">
          <div className="no-page-question-mark1">
            <Emoji symbol="❓" />
          </div>
          <div className="no-page-question-mark2">
            <Emoji symbol="❓" />
          </div>
          <div className="no-page-question-mark3">
            <Emoji symbol="❓" />
          </div>

          <SittingRobot />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUser: item => {
      dispatch({ type: "SET_USER", payload: item });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Homepage);
