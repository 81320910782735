import React from "react";
import "./style.scss";

function menuButton(
  icon,
  title,
  config = { color: "", textColor: "" },
  selected
) {
  return (
    <div
      style={{ backgroundColor: config.color }}
      onClick={config ? config.onClick : () => {}}
      className="menu-button"
      id={selected ? "selected-setting" : ""}
    >
      <div
        className="menu-button-text-container"
        style={{ color: config.textColor }}
      >
        <p className="menu-button-text">{title}</p>
      </div>
      <div className="menu-button-icon">{icon}</div>
      {config && config.menus ? (
        <div className="menu-menu-container">
          <div className="menu-menu">
            <p>{config ? config.menus : ""}</p>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default menuButton;
