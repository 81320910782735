import { url } from "../../environment/paths";
const login = (username, phoneNumber) => {
  const header = {
    "Content-Type": "application/json"
  };
  const body = {
    username,
    phoneNumber
  };
  return fetch(
    `${url}/users/sms`,
    {
      method: "POST",
      header: header,
      body: JSON.stringify(body)
    },
    1000
  )
    .then(res => res.json())
    .catch(err => false);
};

export default login;
