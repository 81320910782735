const gameReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_GAME":
      return action.payload;
    case "CHANGE_GAME":
      const key = Object.keys(action.payload)[0];
      state[key] = action.payload[key];
      return Object.assign({}, state);
    default:
      return state;
  }
};

export default gameReducer;
