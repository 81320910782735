import React, { useState, useEffect } from "react";

import Header from "../../components/header";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import IosArrowDropright from "react-ionicons/lib/IosArrowDropright";
import IosArrowDropleft from "react-ionicons/lib/IosArrowDropleft";

import { connect } from "react-redux";
import zxcvbn from "zxcvbn";
import "./style.scss";

import Scroll from "../../components/scrollScreen";
import userExist from "../../helpers/users/userExist";
import requestSms from "../../helpers/users/requestSms";
import createUser from "../../helpers/users/createUser";
import CheckPanel from "../../components/checkUserPanel";
import Background from "../../components/background";

import IosAnalyticsOutline from "react-ionicons/lib/IosAnalyticsOutline";
import IosAnalytics from "react-ionicons/lib/IosAnalytics";
import IosGameControllerB from "react-ionicons/lib/IosGameControllerB";

let uname = "",
  pword = "",
  nextpword = "",
  score;
let verificationCode = "";
let phoneNr = "";
let sentSms = false;
function LoginPage(props) {
  const { lang } = props;
  const [index, setIndex] = useState(0);
  const [error, setError] = useState();
  const [uExist, setUserExist] = useState();
  const [score, setScore] = useState();
  const [passwordMatch, setPasswordMatch] = useState();
  const [phoneNumberValid, setPhoneNumberValid] = useState(false);

  const moveOn = [
    uExist === false,
    score > 0,
    passwordMatch,
    phoneNumberValid,
    true
  ];
  const {
    next,
    previous,
    passwordWeak0,
    passwordWeak1,
    passwordWeak2,
    passwordWeak3,
    passwordWeak4,
    passwordMatches,
    chooseUsername,
    choosePassword,
    enterEmail,
    enterPhonenumber,
    enterUsername,
    passwordDoesntMatch,
    userDoesntExistClickToMoveOn,
    usernameExist,
    reenterPassword,
    weWillSendMessageToValidate,
    enterSmsCode,
    alreadyHaveAnAccount,
    whatsAStrongPassword,
    phoneNumberExist
  } = global.lang;

  function nextPage() {
    setIndex(index + 1);
  }
  function prevPage() {
    setIndex(index - 1);
  }

  function checkIfUserExist(event) {
    /* Checks if the username input enters SHIFT */
    uname = event.target.value;
    if (uname == "") {
      setUserExist();
    } else {
      userExist(event.target.value).then(value => {
        if (value.userExist != uExist) {
          setUserExist(value.userExist);
        }
      });
    }
  }
  function enterPhoneNr() {}
  function updatePassword(event) {
    /* Checks if the username input enters SHIFT */
    pword = event.target.value;
    const s = zxcvbn(pword).score;
    if (pword == "") {
      setScore();
    }
    if (s != score) {
      setScore(s);
    }
  }
  function updatePasswordReenter(event) {
    /* Checks if the username input enters SHIFT */
    nextpword = event.target.value;
    if (passwordMatch != (pword == nextpword)) {
      setPasswordMatch(pword == nextpword);
    }
  }

  const panel = props => {
    return (
      <div className="signup-panel-single">
        <div className="title-box-panel">
          <h1>{props.title}</h1>
        </div>
        <div className={"login-panel-description-exist"}>
          <p style={{ color: props.descriptionColor }}>{props.description}</p>
        </div>
        <Form.Control
          onKeyDown={e => {
            if (e.keyCode == 9) e.preventDefault();
          }}
          className="form"
          type="email"
          onKeyUp={props.cb}
          type={props.type}
        />
        <a
          href={props.href}
          target={props.new ? "_blank" : ""}
          className="forgot"
        >
          {props.description2nd}
        </a>
      </div>
    );
  };

  const telefonPanel = props => {
    function setPhoneNumber(event) {
      phoneNr =
        document.getElementById("countryPhoneAddon").value + event.target.value;
      const valid = phoneNr.length > 6;
      if (phoneNumberValid != valid) {
        setPhoneNumberValid(valid);
      }
    }
    return (
      <div className="signup-panel-single">
        <div className="title-box-panel">
          <h1>{props.title}</h1>
        </div>
        <div
          className={
            moveOn[index]
              ? "login-panel-description-exist"
              : "login-panel-description-not-exist"
          }
        >
          <p style={{ color: props.descriptionColor }}>{props.description}</p>
        </div>
        <Form.Control
          as="select"
          id="countryPhoneAddon"
          className="phone-land-number"
        >
          <option>+46</option>
        </Form.Control>
        <Form.Control
          onKeyDown={e => {
            if (e.keyCode == 9) e.preventDefault();
          }}
          className="form-phone-number"
          type="email"
          onKeyUp={setPhoneNumber}
          type={props.type}
        />
        <a
          href={props.href}
          target={props.new ? "_blank" : ""}
          className="forgot"
        >
          {props.description2nd}
        </a>
      </div>
    );
  };

  const codePanel = props => {
    if (index == 4 && !sentSms) {
      requestSms(uname, phoneNr).then(res => {
        if (res.statusCode != 200) {
          setError(phoneNumberExist[lang]);
        }
      });
    }
    function change(event, index) {
      if (event.target.value.length < 1) return;
      verificationCode =
        verificationCode.substr(0, index) +
        event.target.value +
        verificationCode.substr(index + 1);

      if (verificationCode.length == 6 && index == 5) {
        setIndex(index);
      }
      const formList = document.forms["code"].elements;
      if (index < formList.length - 1) formList[1 + index].focus();
    }

    return (
      <div className="signup-panel-single">
        <div className="title-box-panel">
          <h1>{props.title}</h1>
        </div>
        <div className={"login-panel-description-exist"}>
          <p style={{ color: props.descriptionColor }}>{props.description}</p>
        </div>
        <Form name="code">
          <div className="code-form-numbers">
            {[0, 1, 2, 3, 4, 5].map(value => (
              <Form.Control
                maxlength="1"
                onKeyUp={event => change(event, value)}
                className="code-panel-single"
                onKeyDown={e => {
                  if (e.keyCode == 9) e.preventDefault();
                }}
              />
            ))}
          </div>
        </Form>
        <a href={props.href} className="forgot">
          {props.description2nd}
        </a>
      </div>
    );
  };

  const cPanel = (
    <CheckPanel
      title={uname}
      lang={props.lang}
      width={40}
      height={40}
      check={() => createUser(uname, pword, phoneNr, verificationCode)}
      sucess={() => (window.location.href = "/home")}
      fail={error => {
        setError(error);
        prevPage();
      }}
    />
  );
  const getDescriptionUsername = (() => {
    if (uname == "") {
      return { description: enterUsername[lang] };
    }
    if (!uExist)
      return {
        description: userDoesntExistClickToMoveOn[lang],
        descriptionColor: "green"
      };
    return { description: usernameExist[lang], descriptionColor: "red" };
  })();
  const passwordReenterDescription = (() => {
    if (!passwordMatch) {
      return {
        description: passwordDoesntMatch[lang],
        descriptionColor: "red"
      };
    }
    return {
      description: passwordMatches[lang]
    };
  })();
  const passwordDescription = (() => {
    if (pword == "") {
      return {
        description: choosePassword[lang],
        descriptionColor: "black"
      };
    }
    if (pword) {
      switch (score) {
        case 0:
          return {
            description: passwordWeak0[lang],
            descriptionColor: "red"
          };
        case 1:
          return {
            description: passwordWeak1[lang],
            descriptionColor: "orange"
          };
        case 2:
          return {
            description: passwordWeak2[lang],
            descriptionColor: "orange"
          };
        case 3:
          return {
            description: passwordWeak3[lang],
            descriptionColor: "green"
          };
        case 4:
          return {
            description: passwordWeak4[lang],
            descriptionColor: "green"
          };
      }
    }
  })();

  const array = [
    panel({
      title: chooseUsername[lang],
      ...getDescriptionUsername,
      cb: checkIfUserExist,
      description2nd: alreadyHaveAnAccount[lang],
      href: "/login"
    }),
    panel({
      title: choosePassword[lang],
      ...passwordDescription,
      type: "password",
      cb: updatePassword,
      description2nd: whatsAStrongPassword[lang],
      new: true,
      href:
        "https://us.norton.com/internetsecurity-how-to-how-to-choose-a-secure-password.html"
    }),
    panel({
      title: reenterPassword[lang],
      type: "password",
      ...passwordReenterDescription,
      cb: updatePasswordReenter
    }),
    telefonPanel({
      type: "number",
      title: enterPhonenumber[lang],
      description: weWillSendMessageToValidate[lang],
      cb: enterPhoneNr,
      descriptionColor: "green"
    }),
    codePanel({
      type: "number",
      title: enterPhonenumber[lang],
      description: enterSmsCode[lang],
      cb: enterPhoneNr,
      descriptionColor: "green"
    }),
    cPanel
  ];

  return (
    <div className="login">
      {error ? (
        <Alert
          key="danger"
          style={{ position: "absolute", width: "100%", textAlign: "center" }}
          variant="danger"
          onClose={() => setError()}
          dismissible
          className="alert"
        >
          <p>{error}</p>
        </Alert>
      ) : (
        <div />
      )}
      <Header value="QTime" />
      <Background />
      <div className="login">
        <div className="login-panel">
          <div>
            <IosGameControllerB
              className="login-icon"
              size="50px"
              color="#4c4c77"
            />
            <div className="login-icon-cable-container">
              <IosAnalyticsOutline
                className="login-icon-cable"
                size="50px"
                color="#ecece2"
              />
            </div>
            <div className="joystick-cable" />
          </div>
          <div className="login-screen">
            <Scroll array={array} width={"40"} height={"40"} index={index} />
          </div>
          <div className="login-screen-static">
            <Button
              onClick={prevPage}
              variant="outline-primary"
              style={{ float: "left" }}
              disabled={!index > 0}
            >
              {previous[props.lang]}
              <IosArrowDropleft
                className="login-screen-static-arrow"
                style={{ float: "left" }}
              />
            </Button>
            <Button
              onClick={nextPage}
              variant="outline-primary"
              style={{ float: "right" }}
              disabled={!(moveOn[index] && index < array.length - 1)}
            >
              {next[props.lang]}

              <IosArrowDropright
                className="login-screen-static-arrow"
                style={{ float: "right" }}
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
