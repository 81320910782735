function timer(cb, wait, delay) {
  setTimeout(
    () => {
      cb();
      timer(cb, wait);
    },
    delay ? delay + wait : wait
  );
}

export default timer;
