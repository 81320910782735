import React, { useState, useEffect } from "react";
import "./style.scss";
import TimerBar from "../../../../components/timerBar";
import ProgressBar from "react-bootstrap/ProgressBar";
import QuestionIcon from "../question-icon";
import { connect } from "react-redux";
import nextQuestion from "../../../../helpers/game/setQuestion";
import anwerQuestion from "../../../../helpers/game/answerQuestion";
import getCorrectAnswer from "../../../../helpers/game/getCorrectAnswer";
function QuestionComponent(props) {
  const { index, question, game, cb, username, token, gameID, correct } = props;
  const [selected, setSelected] = useState(question.answers[username]);
  const [winner, setWinner] = useState();

  function answer(index) {
    anwerQuestion(username, token, index, gameID);
    setSelected(index);
  }

  useEffect(() => {
    setWinner(game.correct);
  }, [game.correct]);

  console.log(game.admin);
  useEffect(() => {
    if (props.selected && game.admin.includes(username)) {
      setTimeout(() => {
        getCorrectAnswer(username, token, gameID);
      }, game.timeout * 1000 + 100);
    }
  }, [props.selected]);

  function getWinner(winner) {
    return (
      <div className="question-answer-winner">
        {question.choices.map((value, index) => (
          <div
            id={
              winner == index
                ? ""
                : index != selected
                ? "selected-question-not"
                : "selected-question"
            }
            className={
              winner == index
                ? "question-single-answer-winner"
                : "question-single-answer-loser"
            }
          >
            <p>{value}</p>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="question">
      <QuestionIcon question={question} index={index} />
      <div className="question-title">
        <p>{question.question}</p>
      </div>
      <TimerBar
        selected={props.selected}
        time={props.game.timeout}
        width={360}
        cb={cb}
      />
      {winner === undefined || winner === null ? (
        <div className="question-answer">
          {question.choices.map((value, index) => (
            <div
              onClick={() => answer(index)}
              id={
                selected != undefined
                  ? index != selected
                    ? "selected-question-not"
                    : "selected-question"
                  : ""
              }
              className="question-single-answer"
            >
              <p>{value}</p>
            </div>
          ))}
        </div>
      ) : (
        getWinner(winner)
      )}

      <h1>{game.currentQuestion + 1}</h1>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    game: state.game,
    lang: state.lang
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionComponent);
