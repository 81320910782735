import React, { useState, useEffect } from "react";

import Header from "../../components/header";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import IosArrowDropright from "react-ionicons/lib/IosArrowDropright";
import IosArrowDropleft from "react-ionicons/lib/IosArrowDropleft";

import { connect } from "react-redux";

import "./style.scss";

import Scroll from "../../components/scrollScreen";
import userExist from "../../helpers/users/userExist";
import login from "../../helpers/users/login";
import CheckPanel from "../../components/checkUserPanel";
import Background from "../../components/background";
import IosAnalyticsOutline from "react-ionicons/lib/IosAnalyticsOutline";
import IosAnalytics from "react-ionicons/lib/IosAnalytics";
import IosGameControllerB from "react-ionicons/lib/MdTrophy";
import loginIcon from "./podium (1).svg";

let uname, pword;

function LoginPage(props) {
  const [index, setIndex] = useState(0);
  const [error, setError] = useState();
  const [uExist, setUserExist] = useState(false);
  const {
    next,
    previous,
    username,
    password,
    loginSucceded,
    userDoesntExist,
    clickToMoveOn,
    forgotUsername,
    forgotPassword,
    createNewAccount
  } = global.lang;

  function nextPage() {
    setIndex(index + 1);
  }
  function prevPage() {
    setIndex(index - 1);
  }

  function checkIfUserExist(event) {
    /* Checks if the username input enters SHIFT */
    uname = event.target.value;
    userExist(event.target.value).then(value => {
      if (value.userExist != uExist) {
        setUserExist(value.userExist);
      }
    });
  }
  function setPassword(event) {
    pword = event.target.value;
  }

  const UsernamePanel = props => {
    return (
      <div className="login-panel-single">
        <div className="title-box-panel">
          <h1>{props.title}</h1>
        </div>
        <div
          className={
            uExist
              ? "login-panel-description-exist"
              : "login-panel-description-not-exist"
          }
        >
          <p>{props.description}</p>
        </div>
        <Form.Control
          onKeyDown={e => {
            if (e.keyCode == 9) e.preventDefault();
          }}
          className="form"
          type="email"
          onKeyUp={checkIfUserExist}
        />
        <a href={props.href} className="forgot">
          {props.description2nd}
        </a>
      </div>
    );
  };
  const PasswordPanel = props => {
    return (
      <div className="login-panel-single">
        <div className="title-box-panel">
          <h1>{props.title}</h1>
        </div>
        <div
          className={
            uExist
              ? "login-panel-description-exist"
              : "login-panel-description-not-exist"
          }
        >
          <p>{props.description}</p>
        </div>
        <Form.Control type="password" onKeyUp={setPassword} className="form" />
        <a href={props.href} className="forgot">
          {props.description2nd}
        </a>
      </div>
    );
  };

  const uPanel = UsernamePanel({
    title: username[props.lang],
    description: uExist
      ? clickToMoveOn[props.lang]
      : userDoesntExist[props.lang],
    description2nd: createNewAccount[props.lang],
    href: "/create"
  });
  const pPanel = PasswordPanel({
    title: password[props.lang],
    description: uname
  });
  const cPanel = (
    <CheckPanel
      title={uname}
      lang={props.lang}
      width={40}
      height={40}
      check={() => login(uname, pword)}
      sucess={value => {
        props.cookies.set("token", value.token);
        props.cookies.set("username", uname);
        window.location.href = "/home";
      }}
      fail={error => {
        setError(error);
        prevPage();
      }}
    />
  );

  const array = [uPanel, pPanel, cPanel];

  return (
    <div className="login">
      <Background />
      {error ? (
        <Alert
          key="danger"
          style={{
            position: "absolute",
            width: "100%",
            textAlign: "center",
            zIndex: 100
          }}
          className="alert"
          variant="danger"
          onClose={() => setError()}
          dismissible
        >
          <p>{error}</p>
        </Alert>
      ) : (
        <div />
      )}

      <Header value="QTime" />
      <div className="login">
        <div className="login-panel">
          <div className="login-icon-container">
            <img src={loginIcon} className="login-icon" color="#ffe600" />
          </div>
          <div className="login-screen">
            <Scroll array={array} width={"40"} height={"40"} index={index} />
          </div>
          <div className="login-screen-static">
            <Button
              onClick={prevPage}
              variant="outline-primary"
              style={{ float: "left" }}
              disabled={!index > 0}
            >
              {previous[props.lang]}
              <IosArrowDropleft
                className="login-screen-static-arrow"
                style={{ float: "left" }}
              />
            </Button>
            <Button
              onClick={nextPage}
              variant="outline-primary"
              style={{ float: "right" }}
              disabled={!(uExist && index < array.length - 1)}
            >
              {next[props.lang]}

              <IosArrowDropright
                className="login-screen-static-arrow"
                style={{ float: "right" }}
              />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
