import React, { useState, useEffect } from "react";
import talkbubble from "./talkbubble.svg";
import { connect } from "react-redux";
import "./style.scss";

let array;
let start = true;
let timer;
const LabIcon = props => {
  const [text, setText] = useState("");

  useEffect(() => {
    array = "Welcome to qtime~~~~~ ~ ~QTime! Click on start game to create a new game. Otherwise wait on invite!".split(
      ""
    );

    setTimeout(() => {
      setText(text + array.shift());
    }, 0);
  }, [props]);

  if (array && array[0]) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (array[0] == "~" && array.shift())
        setText(text.substring(0, text.length - 1));
      else setText(text + array.shift());
    }, 50);
  }

  return (
    <div className="robot-talk-bubble">
      <img className="robot-talk-bubble-image" src={talkbubble} />
      <div className="robot-talk-text">
        <p>{text}</p>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.lang
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabIcon);
