import {url} from "../../environment/paths"
const login = (username, password) => {
    const header = {
        'Content-Type': 'application/json',
      };
    const body = {
        username:username,
        password:password
    }
    return fetch(`${url}/users/login`,{
        method:'POST',
        'header': header,
        body:JSON.stringify(body)
    }, 1000).then(res=>res.json()).catch(err=>false)
}


export default login;
