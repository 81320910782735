/**
 * Module for simple traslation.
 *
 * Keys should be written in camel case and in english.
 *
 * Values should be written to wanted value without dot at the end
 * Some values are more than one sentence remember then that the
 * value can be split into more than one value if it feels neccessary.
 *
 *
 * NOTE key word can be used to make a note for easier finding needed changes
 */
const lang = {
  hello: {
    swe: "Hej",
    eng: "Hello",
    es: "Hola"
  },
  congratulations: {
    swe: "Gratulerar!",
    eng: "Congratulations!",
    es: "Felicitaciones"
  },
  next: {
    swe: "Nästa",
    eng: "Next",
    es: "Próximo"
  },
  previous: {
    swe: "Föregående",
    eng: "Previous",
    es: "Anterior"
  },
  username: {
    swe: "Användarnamn",
    eng: "Username",
    es: "Usuario"
  },
  password: {
    swe: "Lösenord",
    eng: "Password",
    es: "Contraseña"
  },
  loginSucceded: {
    swe: "Inloggning lyckad!",
    eng: "Login successful!",
    es: "Acceso permitido"
  },
  clickToMoveOn: {
    swe: "Klicka Nästa för att gå vidare.",
    eng: "Click Next to move on. ",
    es: "Presione Próximo para continuar"
  },
  userDoesntExist: {
    swe: "Användarnamn är inte i bruk",
    eng: "Username doesn't exist",
    es: "Usuario inexistente"
  },
  forgotUsername: {
    swe: "Glömt bort användarnamn?",
    eng: "Forgot username?",
    es: "¿Has olvidado tu usuario?"
  },
  forgotPassword: {
    swe: "Glömt bort lösenord?",
    eng: "Forgot password?",
    es: "¿Has olvidado tu contraseña?"
  },
  enterUsername: {
    swe: "Skriv in användarnamn",
    eng: "Enter username",
    es: "Introduzca su nombre de usuario"
  },
  enterPassword: {
    swe: "Skriv in lösenord",
    eng: "Enter password",
    es: "Introduzca su contraseña"
  },
  wrongUsernameOrPassword: {
    swe: "Fel vid inloggning. Fel användarnamn eller lösenord.",
    eng: "Wrong username or password.",
    es: "Usuario o contraseña incorrecta"
  },
  // change to create game
  create: {
    swe: "Skapa nytt spel",
    eng: "Create new game",
    es: "Crear nueva partida"
  },
  invites: {
    swe: "Inbjudningar",
    eng: "Invites",
    es: "Invitaciones"
  },
  settings: {
    swe: "Inställningar",
    eng: "Settings",
    es: "Configuraciones"
  },
  addUserString: {
    swe: "Lägg till",
    eng: "Add user",
    es: "Agregar usuario"
  },
  userDoesExist: {
    swe: "Användare finns!",
    eng: "User exists!",
    es: "Nombre de usuario en uso"
  },
  //Very very strange variabel bög
  createGame: {
    swe: "Skapa ny",
    eng: "Create",
    es: "Crear"
  },
  startGame: {
    swe: "Starta",
    eng: "Start",
    es: "Comenzar"
  },
  rules: {
    swe: "Regler",
    eng: "Rules",
    es: "Reglas"
  },
  //StrangeThings se över spelet
  review: {
    swe: "Se över match",
    eng: "Review game ",
    es: "Verificar partida"
  },
  createGameInfo: {
    swe: "Skapa ny fråga",
    eng: "Create new question",
    es: "Crear nueva pregunta"
  },
  rulesInfo: {
    swe: "Välj spel inställningar.",
    eng: "Choose game options.",
    es: "Configurar juego "
  },
  update: {
    swe: "Updatera",
    eng: "Update",
    es: "Actualizar"
  },
  admin: {
    swe: "Administatör",
    eng: "Admin",
    es: "Administrador"
  },
  users: {
    swe: "Spelare",
    eng: "Players",
    es: "Jugadores"
  },
  all: {
    swe: "Alla",
    eng: "Everyone",
    es: "Todos"
  },
  //TVÅ likadana kolla upp
  players: {
    swe: "Spelare",
    eng: "Players",
    es: "Jugadores"
  },
  addUsers: {
    swe: "Lägga till spelare",
    eng: "Add new player",
    es: "Agregar nuevo jugador"
  },
  addQuestions: {
    swe: "Lägga till frågor",
    eng: "Add questions",
    es: "Agregar preguntas"
  },
  // Har gjort en ändring här
  timeoutPerQuestion: {
    swe: "Tid per fråga",
    eng: "Question time limit",
    es: "Tiempo por pregunta"
  },
  chooseUsername: {
    swe: "Välj ett användarnamn",
    eng: "Choose an username",
    es: "Elija usuario"
  },
  choosePassword: {
    swe: "Välj lösenord",
    eng: "Choose password",
    es: "Elija contraseña"
  },
  enterEmail: {
    swe: "Skriv in din email adress",
    eng: "Enter email",
    es: "Introduzca correo electrónico"
  },
  enterPhonenumber: {
    swe: "Skriv in ditt telefonnummer",
    eng: "Enter your phone number",
    /* NOTE is it possible to make a single row?*/
    es: "Introduzca tu numero de teléfono"
  },
  userDoesntExistClickToMoveOn: {
    swe: "Namnet är ledigt klicka för att gå vidare",
    eng: "Username does not exist click to move on",
    es: "Nombre de usuario libre, continue"
  },
  usernameExist: {
    swe: "Namn är tyvärr upptaget",
    eng: "Username is unfortunately occupied",
    es: " El nombre de usuario ya existe"
  },
  passwordWeak0: {
    swe: "Lösenordet är för svagt",
    eng: "Password is too weak",
    es: "Contraseña muy frágil"
  },
  passwordWeak1: {
    swe: "Lösenordet är svagt",
    eng: "Password is weak",
    es: "Contraseña frágil"
  },
  passwordWeak2: {
    swe: "Lösenordet är inte starkt",
    eng: "Password is not strong",
    es: " Contraseña no es segura"
  },
  passwordWeak3: {
    swe: "Lösenordet är starkt",
    eng: "Password is strong",
    es: "Contraseña segura"
  },
  passwordWeak4: {
    swe: "Lösenordet är extremt starkt. Bra!",
    eng: "Password extremely strong. Good!",
    es: " Contraseña muy segura"
  },
  reenterPassword: {
    swe: "Skriv in lösenordet igen!",
    eng: "Reenter password!",
    /* NOTE, is it possible to become single row? */
    es: " Por favor, teclea su contraseña otra vez"
  },
  passwordDoesntMatch: {
    swe: "Lösenorden matchar inte",
    eng: "Password does not match",
    es: "Contraseña no coinciden"
  },
  passwordMatches: {
    swe: "Lösenorden matchar",
    eng: "Password match",
    es: " Contraseña coinciden"
  },
  weWillSendMessageToValidate: {
    swe: "Vi kommer skicka ett sms med verifieringskoden inom kort.",
    eng: "We will send a validation code",
    es: " Le enviaremos un codigo de verificación"
  },

  /* Needs spanish traslation*/
  enterSmsCode: {
    swe: "Skriv SMS kod",

    eng: "Enter SMS code",

    es: "Ingrese el código recibido por sms"
  },

  enterQuestion: {
    swe: "Skriv fråga",

    eng: "Enter question",

    es: "Ingrese la pregunta"
  },

  correctAnswer: {
    swe: "Rätt svar",

    eng: "Correct answer",

    es: "Respuesta correcta"
  },

  wrongAnswer: {
    swe: "Fel svar",

    eng: "Wrong answer",

    es: "Respuesta incorrecta"
  },

  alreadyHaveAnAccount: {
    swe: "Har redan konto?",

    eng: "Already have an account?",

    es: "¿Ya tienes una cuenta?"
  },

  alreadyHaveAnAccount: {
    swe: "Har redan konto?",

    eng: "Already have an account?",

    es: "Alerado havo uno acoounto?"
  },

  whatsAStrongPassword: {
    swe: "Vad är ett starkt lösenord?",

    eng: "Whats a strong password?",

    es: "¿Cuál es una contraseña segura?"
  },

  fieldsMissingInQuestion: {
    swe: "Saknas något fält i frågan.",

    eng: "Missing item in one or more fields in the question",

    es: "Falta un o mas componentes en la pregunta."
  },

  reviewGame: {
    swe: "Se över spelet",

    eng: "Review game",

    es: "Revia el juego"
  },

  numberOfQuestions: {
    swe: "Antal frågor",

    eng: "Number of questions",

    es: "Numero de preguntas"
  },

  scoreBoard: {
    swe: "Poäng tavla",

    eng: "Scoreboard",

    es: "Marcador"
  },

  noPageFound: {
    swe: "Hittar inte vald sida",

    eng: "Can not found page",

    es: "No puedo encontrar la página elegida"
  },

  theIdea: {
    swe: "Tanken",

    eng: "The idea",

    es: "La idea"
  },

  us: {
    swe: "Oss",

    eng: "Us",

    es: "Nosotros"
  },

  loggerInAs: {
    swe: "Inloggad som",

    eng: "Logged in as",

    es: "Conectado como"
  },

  numberOfPlayedGames: {
    swe: "Antal spelade spel",

    eng: "Number of played games",

    es: "Numero de juegos jugados"
  },

  logOut: {
    swe: "Logga ut",

    eng: "Log out",

    es: "Cerrar sesión"
  },

  typeOfUser: {
    swe: "Typ av användare",

    eng: "Type of user",

    es: "Tipo de usuario"
  },

  createNewAccount: {
    swe: "Skapa nytt konto!",

    eng: "Create new account",

    es: "Crear una nueva cuenta"
  },

  aboutUs: {
    swe: "Om oss",

    eng: "About us",

    es: "Sobre nosotros"
  },

  credits: {
    swe: "Tack till!",

    eng: "Thanks to!",

    es: "Gracias a"
  },

  technicalSpec: {
    swe: "Tech",

    eng: "Tech",

    es: "Tecnología"
  },

  aboutUsText1: {
    swe:
      "QTime är ett gäng studenter från KTH och Stockholms Universitet som är intresserade av nya sätt förmedla kunskap på. Genom att ta en teknisk approach vill vi uppmuntra och framförallt underlätta inlärning för skolelever! Är du lärare? Då är du välkommen att använda QTime precis hur du vill. Arbetet med appen har letts av Erik Rehn samt Emilio Gustavsson och om  du har några frågor är de bara att kontakta oss på email aboutqtime@gmail.com. Hoppas att du gillar det vi har jobbat på!",

    eng:
      "QTime is a couple of students from The Royal Institute of Technology and the University of Stockholm. We at QTime are always looking for new ways to deliver knowledge. Using a technical approach we want to encourage and simplify the learning process. Are you a teacher? Feel free to use QTime however you please. The work has been lead by Erik Rehn and Emilio Gustavsson and if you have any questions please contact us at our email aboutqtime@gmail.com. Hope you enjoy our project!",

    es:
      "QTime es un par de estudiantes del Real Instituto de Tecnología y de la Universidad de Estocolmo. En Qtime siempre estamos buscando nuevas formas de distribuir conocimiento. Con un enfoque técnico queremos alentar y simplificar el proceso de aprendizaje.  ¿Eres profesor? Siéntase libre de usar QTime como quiera.  El proyecto ha sido dirigido por Erik Rehn y Emilio Gustavsson. Si tienen alguna pregunta contáctenos a nuestro correo electrónico aboutqtime@gmail.com. Esperamos que disfrute de nuestro proyecto!"
  },

  aboutUsText2: {
    swe:
      "Tack till alla medverkande. Stort tack till de personer som har testat systemet under utveckling av applikationen. Följande ikoner har används till applikationen. https://www.flaticon.com/authors/popcorns-arts https://www.flaticon.com/authors/eucalyp https://www.flaticon.com/authors/freepik https://www.flaticon.com/authors/kiranshastry https://www.flaticon.com/authors/flat-icons https://www.flaticon.com/authors/prettycons",

    eng:
      "We would like to thank all the persons who helped build the website. Special thanks to the people testing our application during the buildstage.  The following icons have been use in the application. https://www.flaticon.com/authors/popcorns-arts https://www.flaticon.com/authors/eucalyp https://www.flaticon.com/authors/freepik https://www.flaticon.com/authors/kiranshastry https://www.flaticon.com/authors/flat-icons https://www.flaticon.com/authors/prettycons",

    es:
      "Nos gustaría agradecer a todas las personas que nos ayudaron a desarrollar el sitio web. Un agradecimiento especial a las personas que prabaron nuestra aplicación durante la etapa de construcción. Los siguientes íconos se han utilizado en la aplicación. https://www.flaticon.com/authors/popcorns-arts https://www.flaticon.com/authors/eucalyp https://www.flaticon.com/authors/freepik https://www.flaticon.com/authors/kiranshastry https://www.flaticon.com/authors/flat-icons https://www.flaticon.com/authors/prettycons"
  },

  aboutUsText3: {
    swe:
      "För den lite mer teknisk lagda! Hela denna application är hostad på cloud. Funktionaliteten ligger på AWS (Amazon Web Services) och består av ett nätverk av deras tjänster med lambdafunktioner som bas. Front-end är skriven i React med Redux. Denna konfiguration gör att vi kan enkelt skala upp applikationen och dra ned på kostnaderna samtidigt! Vi har gjort valet att för tillfället behålla back-end för oss själva. Om ni har några funderingar eller vill begära ut sourcecoden är de bara att skriva till vår kontakt email på aboutqtime@gmail.com. Hoppas vi hörs!",

    eng:
      "For the more technical person in the room. This whole appilcation is hosted on a cloud service. The functionality of the application is on AWS (Amazon Web Services) and consist of a network of their services with lambdafunctions as base. The front-end is written in React with Redux. This configurations allows us to easily scale up the site as well as keep the cost down. We have currently decided to keep the back-end to ourselves. If you have any questions or want to see the sourcecode feel free to cantact us at out email aboutqtime@gmail.com. Hope you enjoy!",

    es:
      "Para la persona más técnica en la sala. Toda esta aplicación está alojada en un servicio cloud. La funcionalidad de la aplicación está en AWS (Amazon Web Services) y consiste de una red de sus servicios con funciones lambda como base. El front-end está escrito en React con Redux. Esta configuracion nos permite escalar fácilmente el sitio y mantener el back-end para nosotros. Si tienen alguna duda o desea ver el código fuente puede contactarnos en nuestro correo electrónico aboutqtime@gmail.com. "
  },

  phoneNumberExist: {
    swe:
      "Tyvärr har redan telefon nummret redan används. Om har problem kan du kontakta oss på vår email.",

    eng:
      "Unfortunately is the phone number already in use. If you have issue you can contact us at our email.",

    es:
      "Lamentablemente el número de teléfono ya está en uso. Si tiene algún problema puede contactarnos en nuestro correo electrónico."
  }
};
global.lang = lang;

export default lang;
