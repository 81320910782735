import React from "react";
import "./style.scss";

function NoticeButton(icon, title, config = { color: "", textColor: "" }) {
  return (
    <div
      style={{ backgroundColor: config.color }}
      onClick={config ? config.onClick : () => {}}
      className="home-button"
    >
      <div
        className="home-button-text-container"
        style={{ color: config.textColor }}
      >
        <p className="home-button-text">{title}</p>
      </div>
      <div className="home-button-icon">{icon}</div>
      {config && config.notices ? (
        <div className="home-notice-container">
          <div className="home-notice">
            <p className="home-notice-text">{config ? config.notices : ""}</p>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default NoticeButton;
