import getSocket from "./index";

function runCommand(username, token, gameID, commands) {
  const socket = getSocket();

  console.log(commands);
  const data = {
    username,
    token,
    commands: commands,
    gameID
  };
  const i = {
    action: "UPDATE",
    data: data
  };
  socket.json(i);
}
export default runCommand;
