import React from "react";
import "./style.scss";
import LangSelector from "../langSelector";
import IosOptions from "react-ionicons/lib/IosOptions";
import Logo from "../logo";

export default function Background(props) {
  return (
    <div className="background-color">
      <div className="circle-1" />
      <div className="circle-2" />
      <div className="circle-3" />
      <div className="circle-4" />
    </div>
  );
}
