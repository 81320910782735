import React, { useState } from "react";
import "./style.scss";
import IosOptions from "react-ionicons/lib/IosOptions";
import { connect } from "react-redux";
import settings from "./settings.svg";
import Button from "react-bootstrap/Button";
import { withCookies } from "react-cookie";

const Options = props => {
  const { loggerInAs, numberOfPlayedGames, logOut, typeOfUser } = global.lang;
  const { lang } = props;
  const [visible, setVisible] = useState(false);

  function onClick() {
    setVisible(!visible);
  }
  function logout() {
    const cookies = props.cookies.getAll();
    const keys = Object.keys(cookies);
    keys.map(value => props.cookies.remove(value));
    window.location.href = "/login";
  }
  return (
    <div className="header-settings-selector">
      {props.user.username && (
        <IosOptions fontSize="100%" color="white" onClick={onClick} />
      )}

      {visible && (
        <div
          className="side-bar-header"
          onClick={() => (window.location.href = "/settings")}
        >
          <img src={settings} />
          <h3>⚙️ {loggerInAs[lang] + " " + props.user.username}</h3>
          <h3>
            ⚙️ {numberOfPlayedGames[lang] + " " + props.user.games.length}
          </h3>
          <h3>⚙️ {typeOfUser[lang] + " " + props.user.role}</h3>
          <Button className="side-bar-button" onClick={logout}>
            {logOut[lang]}
          </Button>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.lang,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLang: item => {
      dispatch({ type: "SET", payload: item });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(Options));
