import React from "react";
import "./style.scss";
import Header from "../../components/header";
import Background from "../../components/background";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Emoji from "../../components/emoji";
import { connect } from "react-redux";
import manufacture from "./manufacture.svg";
import ai from "./artificial-intelligence.svg";
import astronout from "./astronaut.svg";
import problem from "./problem.svg";
const index = props => {
  const { lang } = props;
  const {
    settings,
    aboutUs,
    theIdea,
    us,
    aboutUsText1,
    aboutUsText2,
    aboutUsText3,
    idea,
    technicalSpec,
    credits
  } = global.lang;

  return (
    <div>
      <Background />
      <Header value={"QTime"} />
      <div>
        <div className="about-container">
          <div className="score-board" id="about-board">
            <div className="score-board-title">
              <p>{aboutUs[lang]}</p>
            </div>
            <div className="about-board-conent">
              <div className="about-board-panel">
                <div className="about-board-row">
                  <h3>{us[lang]}</h3>
                  <div className="about-icon-right">
                    <img src={manufacture} />
                  </div>
                  <p>{aboutUsText1[lang]}</p>
                </div>
                <div className="about-board-row">
                  <h3>{credits[lang]}</h3>
                  <div className="about-icon-left">
                    <img src={ai} />
                  </div>
                  <p>{aboutUsText2[lang]}</p>
                </div>
                <div className="about-board-row">
                  <h3>{technicalSpec[lang]}</h3>
                  <div className="about-icon-right">
                    <img src={astronout} />
                  </div>
                  <p>{aboutUsText3[lang]} </p>
                </div>
              </div>
            </div>

            <div className="settings-settings" />
          </div>
        </div>
      </div>
    </div>
  );
};

function Settings({ value }) {
  return (
    <div className="invites-board">
      <div className="invites-board-icon">
        <Emoji symbol="⚙️" />
      </div>
      <div className="invites-board-name">
        <Form.Label>{value}</Form.Label>
      </div>
      <div className="settings-board-value">
        <Form.Check />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUser: item => {
      dispatch({ type: "SET_USER", payload: item });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);
