import {url} from "../../environment/paths"
const createGame = (username, token) => {
    const header = {
        'Content-Type': 'application/json',
      };
    const body = {
        username,
        token
    }
    
    return fetch(`${url}/game/start`,{
        method:'POST',
        'header': header,
        body:JSON.stringify(body)
    }, 1000).then(res=>res.json()).catch(err=>false)
}


export default createGame;
