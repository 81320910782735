import {url} from "../../environment/paths"

const userExist = (username) => {
    const header = {
        'Content-Type': 'application/json',
      };
    const body = {
        username:username,
    }
 
    return fetch(`${url}/users/exists`,{
        method:'POST',
        'header': header,
        body:JSON.stringify(body)
    }).then(res=>res.json());
}


export default userExist;
