import React from "react";

import IosCheckmarkCircle from "react-ionicons/lib/IosCheckmarkCircle";
import "./style.scss";

const CorrectPanel = props => {
  const style = {
    width: props.width + "vh",
    height: props.height + "vh"
  };
  return (
    <div style={style} className="correct-panel">
      <div className={props.selected ? "correct-icon" : ""}>
        <IosCheckmarkCircle fontSize="100%" color="#16d316" />
      </div>
      <h1 className="correct-text">{props.title}</h1>
    </div>
  );
};
export default CorrectPanel;
