import React, { useState } from "react";

import LoadingPanel from "../loadingPanel";
import CorrectPanel from "../correctPanel";
import IncorrectPanel from "../incorrectPanel";

let done = false;

const CheckPanel = props => {
  const { title, width, height, selected, lang } = props;
  const { wrongUsernameOrPassword } = global.lang;
  let [sucess, setSucess] = useState();

  if (props.selected && !done) {
    props.check().then(value => {
      done = true;
      const worked = value ? true : false;
      setSucess(worked);
      if (worked && props.sucess) {
        setTimeout(() => props.sucess(value), 600);
      }
      if (!worked && props.fail) {
        setTimeout(() => props.fail(wrongUsernameOrPassword[lang]), 600);
      }
    });
  }
  if (!props.selected) {
    done = false;
    if (sucess != undefined) {
      setSucess();
    }
  }
  return !done ? (
    <LoadingPanel title={title} width={width} height={height} />
  ) : sucess ? (
    <CorrectPanel
      title={title}
      width={width}
      height={height}
      selected={selected}
    />
  ) : (
    <IncorrectPanel
      title={title}
      width={width}
      height={height}
      selected={selected}
    />
  );
};
export default CheckPanel;
