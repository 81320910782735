import React, { useState } from "react";
import IosCreate from "react-ionicons/lib/IosCreateOutline";
import IosContacts from "react-ionicons/lib/IosContactsOutline";
import IosSettings from "react-ionicons/lib/IosSettingsOutline";
import MdInformationCircle from "react-ionicons/lib/MdInformationCircle";
import "./style.scss";
import Header from "../../components/header";
import { connect } from "react-redux";
import NoticeButton from "../../components/NoticeButton";
import Background from "../../components/background";
import robot from "./robot (3).svg";
import TalkBubble from "./talkBubble";
import getUser from "../../helpers/users/getUser";
function Homepage(props) {
  const { create, invites, settings, aboutUs } = global.lang;
  const { lang } = props;

  const username = props.cookies.get("username");
  const token = props.cookies.get("token");

  if (!username || !token) {
    window.location.href = "/login";
  }

  if (!props.user._id) {
    console.log(props.user);
    getUser(username, token).then(value => props.setUser(value.user));
  }

  function gotoNewGame() {
    window.location.href = "/lobby";
  }
  function gotoSettings() {
    window.location.href = "/settings";
  }
  function gotoInvites() {
    window.location.href = "/invites";
  }
  function gotoAbout() {
    window.location.href = "/about";
  }
  const CreateButton = () =>
    NoticeButton(<IosCreate fontSize="100%" />, create[lang], {
      onClick: gotoNewGame
    });
  const InvitesButton = () =>
    NoticeButton(<IosContacts fontSize="80%" />, invites[lang], {
      onClick: gotoInvites,
      notices: props.user.invites ? props.user.invites.length : undefined
    });
  const SettingsButton = () =>
    NoticeButton(<IosSettings fontSize="80%" />, settings[lang], {
      onClick: gotoSettings
    });
  const AboutButton = () =>
    NoticeButton(<MdInformationCircle fontSize="80%" />, aboutUs[lang], {
      onClick: gotoAbout
    });

  return (
    <div className="home">
      <Background />
      <Header value={"QTime"} />
      <div className="home-robot-icon">
        <img src={robot} className="robot-icon" />
        <div className="robot-talk-bubble">
          <TalkBubble />
        </div>
      </div>
      <div className="home-buttons">
        <CreateButton />
        <InvitesButton />

        <AboutButton />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUser: item => {
      dispatch({ type: "SET_USER", payload: item });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Homepage);
