import React, { useState, useEffect } from "react";
import getScore from "../../../../helpers/game/getScore";
import Emoji from "../../../../components/emoji";
import { connect } from "react-redux";

import "./style.scss";
let first = true;

function QuestionIcon(props) {
  const { game, username, token, gameID, lang } = props;
  const { scoreBoard } = global.lang;

  if (props.selected && first && username == game.admin) {
    console.log("Getting score");
    setTimeout(() => getScore(username, token, gameID), 100);
    first = false;
  }

  console.log(game.score);
  return (
    <div className="score-board">
      <div className="score-board-title">
        <p>{scoreBoard[lang]}</p>
      </div>
      <div className="score-board-players">
        {game.score ? (
          game.score.map((value, index) => getPlayer(value, index, game))
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}

function getPlayer(player, index, game) {
  if (player.data.winner) {
    return <div>{getWinner(player, index, game)}</div>;
  }
  return (
    <div>
      <div className="player-score-board">
        <div className="player-score-board-icon">
          <p>{player.name[0].toUpperCase()}</p>
        </div>
        <div className="player-score-board-name">
          <p>{player.name}</p>
        </div>
        <div className="player-score-board-value">
          <p>
            {player.data.value}/{game.numberOfQuestions}
          </p>
        </div>
      </div>
    </div>
  );
}

function getWinner(player, index, game) {
  return (
    <div className="player-score-board">
      <div className="player-score-board-icon">
        <Emoji symbol="👑" />
      </div>
      <div className="player-score-board-name">
        <p>{player.name}</p>
      </div>
      <div className="player-score-board-value">
        <p>
          {player.data.value}/{game.numberOfQuestions}
        </p>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    game: state.game,
    lang: state.lang
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionIcon);
