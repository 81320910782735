import React from "react";

import IosCloseCircle from "react-ionicons/lib/IosCloseCircle";
import "./style.scss";

const CorrectPanel = props => {
  const style = {
    width: props.width + "vh",
    height: props.height + "vh"
  };
  return (
    <div style={style} className="incorrect-panel">
      <div className={props.selected ? "incorrect-icon" : ""}>
        <IosCloseCircle fontSize="100%" color="red" />
      </div>
      <h1 className="incorrect-text">{props.title}</h1>
    </div>
  );
};
export default CorrectPanel;
