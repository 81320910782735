import Cookies from "universal-cookie";
const cookies = new Cookies();
const lang = cookies.get("lang") || "swe";
const langReducer = (state = lang, action) => {
  switch (action.type) {
    case "SET":
      return action.payload;
    default:
      return state;
  }
};

export default langReducer;
