import { url } from "../../environment/paths";
const createUser = (username, password, phoneNumber, token) => {
  const header = {
    "Content-Type": "application/json"
  };
  const body = {
    username,
    password,
    phoneNumber,
    token
  };
  return fetch(
    `${url}/users/create`,
    {
      method: "POST",
      header: header,
      body: JSON.stringify(body)
    },
    1000
  )
    .then(res => res.json())
    .catch(err => false);
};

export default createUser;
