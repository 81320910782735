import React from "react";
import { Switch, Route } from "react-router-dom";

import HomePage from "./pages/homepage/";
import Login from "./pages/login";
import Lobby from "./pages/lobby";
import CreateUser from "./pages/createUser";
import Game from "./pages/game";
import Invites from "./pages/invites";
import NotFound from "./pages/404";
import Settings from "./pages/settings";
import About from "./pages/about";
import { withCookies } from "react-cookie";

const Router = props => {
  return (
    <Switch>
      <Route
        exact
        path="/home"
        component={() => <HomePage cookies={props.cookies} />}
      />
      <Route
        exact
        path="/"
        component={() => <HomePage cookies={props.cookies} />}
      />
      <Route
        exact
        path="/about"
        component={() => <About cookies={props.cookies} />}
      />
      <Route
        exact
        path="/settings"
        component={() => <Settings cookies={props.cookies} />}
      />
      <Route
        exact
        path="/login"
        component={() => <Login cookies={props.cookies} />}
      />
      <Route
        path="/game/:id"
        component={params => <Game cookies={props.cookies} {...params} />}
      />
      <Route
        path="/lobby/:id"
        component={params => <Lobby cookies={props.cookies} {...params} />}
      />
      )}/>
      <Route
        exact
        path="/lobby"
        component={params => <Lobby cookies={props.cookies} {...params} />}
      />
      <Route
        exact
        path="/create"
        component={params => <CreateUser {...params} />}
      />
      <Route
        exact
        path="/invites"
        component={params => <Invites cookies={props.cookies} {...params} />}
      />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Router;
