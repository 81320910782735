import React from "react";
import "./style.scss";
/**
 *
 * @param {*} props
 */
const Scroll = props => {
  const { array } = props;
  for (let i in array) {
    array[i] = React.cloneElement(array[i], { selected: i == props.index });
  }
  const style = {
    height: props.height,
    width: props.width * array.length + "vh",
    left: props.width * -props.index + "vh",
    transition: "left 0.5s"
  };

  const singleStyle = {
    height: props.height + "vh",
    width: props.width + "vh"
  };

  const mainDiv = (
    <div className="single-sceen-panel" style={singleStyle}>
      <div className="multiple-sceen-panel" style={style}>
        {array}
      </div>
    </div>
  );

  return mainDiv;
};

export default Scroll;
