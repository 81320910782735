import React, { useState } from "react";
import "./style.scss";
import IosCheckmark from "react-ionicons/lib/IosCheckmark";

import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import userExist from "../../../../helpers/users/userExist";
import addUser from "../../../../helpers/game/addUser";

let uname;

const StartGameView = props => {
  const { userDoesntExist, addUserString, userDoesExist } = global.lang;

  const [uExist, setUserExist] = useState(false);

  function checkIfUserExist(event) {
    uname = event.target.value;
    /* Checks if the username input enters SHIFT */
    userExist(event.target.value).then(value => {
      if (value.userExist != uExist) {
        setUserExist(value.userExist);
      }
    });
  }

  function addNewUser() {
    props.addInvite(uname);
    props.handleClose();
    addUser(props.username, props.token, uname, props.gameID);
  }

  return (
    <Modal show onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{addUser[props.lang]}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control onKeyUp={checkIfUserExist} />
        <div className={uExist ? "user-exist" : "user-doesnt-exist"}>
          {!uExist ? (
            <p>{userDoesntExist[props.lang]}</p>
          ) : (
            <p>{userDoesExist[props.lang]}</p>
          )}
        </div>
        <Button
          onClick={addNewUser}
          className="user-exist-button"
          disabled={!uExist}
        >
          {addUserString[props.lang]}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    game: state.game,
    lang: state.lang
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addInvite: item => {
      dispatch({ type: "ADD_INVITE", payload: item });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StartGameView);
