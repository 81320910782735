import { url } from "../../environment/paths";

const userExist = (username, token) => {
  const header = {
    "Content-Type": "application/json"
  };
  const body = {
    username,
    token
  };

  return fetch(`${url}/users/get`, {
    method: "POST",
    header: header,
    body: JSON.stringify(body)
  }).then(res => res.json());
};

export default userExist;
