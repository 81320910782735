import React from "react";
import { useState } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./style.scss";
import { connect } from "react-redux";
import IosFlag from "react-ionicons/lib/IosFlag";
import IosFlagOutline from "react-ionicons/lib/IosFlagOutline";

import ReactCountryFlag from "react-country-flag";
import { withCookies } from "react-cookie";

const flags = {
  swe: {
    code: "SE",
    title: "Swedish",
    lang: "swe"
  },
  eng: {
    code: "GB",
    title: "English",
    lang: "eng"
  },
  es: {
    code: "ES",
    title: "Spanish",
    lang: "es"
  }
};

function LangSelector(props) {
  function setLang(value) {
    props.cookies.set("lang", value);
    props.setLang(value);
  }
  console.log(props);

  function getFlag(value) {
    const { code } = value;
    return (
      <div className="lang-selector-flag">
        <ReactCountryFlag
          code={code}
          styleProps={{
            width: "20px",
            height: "20px"
          }}
          svg
        />
      </div>
    );
  }

  function getNavItem(value) {
    const { title, lang } = value;
    return (
      <NavDropdown.Item
        onClick={() => setLang(lang)}
        className="lang-selector-dd-item"
      >
        {getFlag(value)}
        <p>{title}</p>
      </NavDropdown.Item>
    );
  }

  return (
    <div className="lang-selector-form">
      <div className="lang-selector-dd-flag">
        <NavDropdown
          className="lang-selector"
          title={getFlag(flags[props.lang])}
        >
          {Object.keys(flags).map(value => getNavItem(flags[value]))}
        </NavDropdown>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLang: item => {
      dispatch({ type: "SET", payload: item });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCookies(LangSelector));
