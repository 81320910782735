import React, { useState } from "react";
import "./style.scss";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";

const DropdownMenu = props => {
  const { list = [], selected = [], title = "title" } = props;
  const [header, setHeader] = useState(title);

  function cb(value, s) {
    if (!props.static) {
      if (s) {
        setHeader(selected[value]);
      } else {
        setHeader(list[value]);
      }
    }
    if (props.cb) props.cb(value, s);
  }
  return (
    <DropdownButton
      {...props}
      className="drop-down"
      drop="right"
      title={header}
    >
      {list.map((value, index) => (
        <Dropdown.Item onClick={() => cb(index, false)} eventKey={index}>
          {value}
        </Dropdown.Item>
      ))}
      <Dropdown.Divider />
      {selected.map((value, index) => (
        <Dropdown.Item onClick={() => cb(index, true)} eventKey={index}>
          {value}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};
export default DropdownMenu;
