import React from "react";

import MdHand from "react-ionicons/lib/MdHand";
import IosCube from "react-ionicons/lib/IosCube";
import IosCubeOutline from "react-ionicons/lib/IosCubeOutline";
import MdPaperPlane from "react-ionicons/lib/MdPaperPlane";
import IosIonitronOutline from "react-ionicons/lib/IosIonitronOutline";
import "./style.scss";
import globe from "./globe.svg";

const AddedQuestionAnimation = props => {
  return (
    <div className="add-question-animation">
      <div>
        <img src={globe} className="cube2" color="#c3c3c3" />
      </div>

      {props.animation ? (
        <div className="add-question-animation-running">
          <MdPaperPlane fontSize="100%" />
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};
export default AddedQuestionAnimation;
