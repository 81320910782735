import Sockette from "sockette";
import { socketUrl } from "../../environment/paths";

let id;
let ws;

export default function(callback, onOpen, gameID) {
  if (!callback || !onOpen || !gameID) return ws;
  if (id == gameID) return ws;

  id = gameID;

  ws = new Sockette(socketUrl, {
    timeout: 5e3,
    maxAttempts: 1,
    onopen: onOpen,
    onmessage: e => callback(e),
    onreconnect: e => console.log("Reconnecting...", e),
    onmaximum: e => console.log("Stop Attempting!", e),
    onclose: e => console.log("Closed!", e),
    onerror: e => console.log("Error:", e)
  });

  return ws;
}
