import React, { useState } from "react";
import "./style.scss";
import MdPerson from "react-ionicons/lib/MdPerson";
import IosBrush from "react-ionicons/lib/IosBrush";
import MdHeart from "react-ionicons/lib/MdHeart";
import LabIcon from "../../../../components/labIcon";

import MenuButton from "../../../../components/MenuButton";
import Button from "react-bootstrap/Button";

import IosCheckmarkCircle from "react-ionicons/lib/IosCheckmarkCircle";
import IosCloseCircle from "react-ionicons/lib/IosCloseCircle";
import ReviewGame from "../reviewGame";
import DropdownButton from "../../../../components/DropdownMenu";
import updateGame from "../../../../helpers/game/updateGame";
import Form from "react-bootstrap/Form";
import StartGameView from "../startGameView";
import setStarted from "../../../../helpers/game/setStarted";
import addQuestion from "../../../../helpers/game/addQuestion";
import AddedQuestionAnimation from "../addedQuestionAnimation";
import joystick from "./joystick.svg";
const colors = ["#325d32", "#424275", "#1f1f46", "#f0eacf"];
const iconColors = ["white", "white", "white", "gray"];
const textColors = ["white", "white", "white", "black"];
let questionString = "";

let animation = false;

function getView(config) {
  const {
    cb = () => {},
    body,
    header,
    info,
    index,
    icon,
    icon2,
    buttonText
  } = config;

  return (
    <div className="settings-body">
      <div className="settings-body-header">
        <h1>{header}</h1>

        <div className="settings-body-info">
          <p>{info}</p>
        </div>
        <div className="settings-body-icon">{icon}</div>
      </div>
      <div className="setting-button-move-on">
        <Button onClick={cb} variant="outline-primary">
          {buttonText}
        </Button>
      </div>
      <div className="setting-body">{body}</div>
    </div>
  );
}

const Settings = props => {
  const commands = {};
  const anim = animation;
  animation = false;

  const [startGameBool, setStartGameBool] = useState(false);
  const [index, setIndex] = useState(0);
  const { lang, game, username, token, gameID, socket, setError } = props;
  const {
    createGame,
    update,
    startGame,
    rules,
    rulesInfo,
    createGameInfo,
    review,
    all,
    enterQuestion,
    wrongAnswer,
    correctAnswer,
    fieldsMissingInQuestion,
    reviewGame
  } = global.lang;

  const getSelector = (
    question,
    title,
    parsed,
    list,
    selected,
    cb = updateRuleValues
  ) => {
    function callb(value, selected) {
      cb(value, selected, parsed);
    }

    return (
      <div className="question-row">
        <div className="setting-body-item">
          <div className="setting-body-item-1st">
            <p>{question}</p>
          </div>
          <div className="setting-body-item-2st">
            <DropdownButton
              cb={callb}
              title={title}
              list={
                list || game.meta.permissions.map(v => global.lang[v][lang])
              }
              selected={selected || [all[lang]]}
            />
          </div>
        </div>
      </div>
    );
  };

  const getTextField = (question, rows) => {
    return (
      <Form id="question-form" className="question-form">
        <div className="add-new-question-animation-icon">
          <AddedQuestionAnimation animation={anim} />
        </div>
        <div className="question-row">
          <div className="first-question-row">
            <div className="setting-body-item-1st">
              <p>{enterQuestion[lang]}</p>
            </div>
            <Form.Control
              required
              className="question-input"
              as="textarea"
              rows={3}
            />
          </div>
        </div>
        <div className="question-row">
          <div className="setting-body-item-1st">
            <p>{correctAnswer[lang]}</p>
          </div>

          <Form.Control
            className="question-input-single"
            as="textarea"
            rows={1}
            required
          />
          <div className="answer-icon">
            <IosCheckmarkCircle fontSize="25px" color="green" />
          </div>
        </div>
        <div className="question-row">
          <div className="setting-body-item-1st">
            <p>{wrongAnswer[lang]}</p>
          </div>
          <Form.Control
            className="question-input-single"
            as="textarea"
            rows={1}
            required
          />
          <div className="answer-icon">
            <IosCloseCircle fontSize="25px" color="red" />
          </div>
        </div>
        <div className="question-row">
          <div className="setting-body-item-1st">
            <p>{wrongAnswer[lang]}</p>
          </div>
          <Form.Control
            className="question-input-single"
            as="textarea"
            rows={1}
            required
          />
          <div className="answer-icon">
            <IosCloseCircle fontSize="25px" color="red" />
          </div>
        </div>
      </Form>
    );
  };

  const updateRuleValues = (value, selected, parsed) => {
    if (parsed == "setTimeout") {
      commands[parsed] = game.meta.time[value];
    } else {
      let v = game.meta.permissions[value];
      if (selected) {
        v = "players";
      }
      if (!commands.updatePermissions) commands.updatePermissions = {};
      commands.updatePermissions[parsed] = v;
    }
    // socket.json({ action: "UPDATE", data: { username, token, commands } });
  };
  const updateRules = () => {
    const data = {
      username,
      token,
      commands: commands,
      gameID
    };
    const i = {
      action: "UPDATE",
      data: data
    };

    socket.json(i);
  };

  const addNewQuestion = e => {
    const form = document.getElementById("question-form");
    const valid = form.checkValidity();
    const question = form[0].value;
    const answers = [1, 2, 3].map(v => form[v].value);
    if (!valid) {
      setError(fieldsMissingInQuestion[lang]);
    } else {
      animation = true;
      addQuestion(username, token, question, answers, gameID);
    }
  };

  const permissions = Object.keys(game.meta.allowKeys);

  const RulesView = () =>
    getView({
      header: rules[props.lang],
      info: rulesInfo[lang],
      buttonText: update[lang],
      icon: <LabIcon fontSize="80px" />,
      body: [
        ...permissions.map((value, index) =>
          getSelector(
            global.lang[value][lang],
            global.lang[game.allowKeys[value]][lang],
            value
          )
        ),
        getSelector(
          global.lang["timeoutPerQuestion"][lang],
          game.timeout,
          "setTimeout",
          game.meta.time,
          [30]
        )
      ],
      index: 0,
      cb: updateRules
    });
  const CreateView = () =>
    getView({
      header: createGame[lang],
      info: createGameInfo[lang],
      cb: addNewQuestion,
      buttonText: createGame[lang],
      body: [getTextField("Enter question", " ")],
      index: 1
    });
  const ReviewView = () =>
    getView({
      header: review[props.lang],
      info: reviewGame[lang],
      index: 2,
      cb: moveOnToGame,
      buttonText: startGame[lang],
      body: <ReviewGame lang={lang} game={game} />,
      icon: <img className="review-game-icon" src={joystick} />
    });
  const StartView = () =>
    getView({
      header: startGame[props.lang],
      index: 3,
      body: <ReviewGame />,
      icon: <LabIcon fontSize="80px" />
    });

  const SettingsBody = props => {
    switch (props.index) {
      case 0:
        return <RulesView />;
      case 1:
        return <CreateView />;
      case 2:
        return <ReviewView />;
      case 3:
        return <ReviewView />;
    }
  };

  const PermissionsButton = () =>
    MenuButton(
      <IosBrush fontSize="100%" color={iconColors[0]} />,
      rules[props.lang].toUpperCase(),
      {
        color: colors[0],
        textColor: textColors[0],
        onClick: () => setIndex(0)
      },
      index === 0
    );
  const CreateButton = () =>
    MenuButton(
      <IosBrush fontSize="100%" color={iconColors[1]} />,
      createGame[props.lang].toUpperCase(),
      {
        color: colors[1],
        textColor: textColors[1],
        onClick: () => setIndex(1)
      },
      index === 1
    );
  const ReviewButton = () =>
    MenuButton(
      <IosBrush fontSize="100%" color={iconColors[2]} />,
      review[props.lang].toUpperCase(),
      {
        color: colors[2],
        textColor: textColors[2],
        onClick: () => setIndex(2)
      },
      index === 2
    );
  const SettingsButton = () =>
    MenuButton(
      <IosBrush fontSize="100%" color={iconColors[3]} />,
      startGame[props.lang].toUpperCase(),
      {
        color: colors[3],
        textColor: textColors[3],
        onClick: moveOnToGame
      },
      index === 3
    );

  function moveOnToGame() {
    setStarted(username, token, true, gameID);
  }

  return (
    <div className="game-settings">
      <div className="settings-buttons">
        {startGameBool ? (
          <StartGameView handleClose={() => setStartGameBool(false)} />
        ) : (
          <div />
        )}
        <PermissionsButton />
        <CreateButton />
        <ReviewButton />
        <SettingsButton />
      </div>
      <SettingsBody index={index} />
    </div>
  );
};

export default Settings;
