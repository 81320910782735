import React, { useState, useEffect } from "react";
import PlayerView from "./playerView";
import MdPersonAdd from "react-ionicons/lib/MdPersonAdd";
import IosCall from "react-ionicons/lib/IosCall";
import MdRibbon from "react-ionicons/lib/MdRibbon";
import MdPerson from "react-ionicons/lib/MdPerson";
import IosMoreOutline from "react-ionicons/lib/IosMoreOutline";
import "./style.scss";
import AddUserView from "./addUserView";
import Settings from "./settings";
import setStarted from "../../../helpers/game/setStarted";
import Header from "../../../components/header";
const color = ["-", "-", "-"];

const GameView = props => {
  const { username, token, game } = props;
  console.log(token);
  const { players, invited } = game;
  const [addPlayer, setAddPlayer] = useState(false);

  function RetriveSymbols(user) {
    const icons = [];

    if (user == game.admin) icons.push(<MdRibbon color="yellow" />);
    else {
      icons.push(<MdPerson color="yellow" />);
    }
    if (game.invited.includes(user)) {
      icons.push(<IosMoreOutline beat={true} color="yellow" />);
    }
    return icons;
  }
  function toggleAddUser() {
    setAddPlayer(!addPlayer);
  }

  useEffect(() => {}, []);

  if (props.game && props.game.started) {
    window.location.href = `/game/${props.game._id}`;
  }

  const AddPlayerSymbol = () => {
    for (let i in game.allowKeys.addUsers) {
      const values = game[game.allowKeys.addUsers[i]];
      console.log(values.includes && values.includes(username));
      if (values == username || (values.includes && values.includes(username)))
        return PlayerView({
          name: <MdPersonAdd fontSize={50} color="white" />,
          onClick: toggleAddUser
        });
    }
    return <div />;
  };
  const PlayerSymbols = () =>
    [...players, ...invited].map((name, index) =>
      PlayerView({ color: color[index], name, icons: RetriveSymbols(name) })
    );

  return (
    <div className="game-view">
      {addPlayer ? (
        <AddUserView handleClose={toggleAddUser} {...props} />
      ) : (
        <div />
      )}
      <div className="players-view">
        <PlayerSymbols />
        <AddPlayerSymbol />
      </div>
      <div className="setting-button">
        <Settings {...props} />
      </div>
    </div>
  );
};

export default GameView;
