import React, { useEffect } from "react";
import "./style.scss";
import img1 from "./img/img1.svg";
import img2 from "./img/img2.svg";
import img3 from "./img/img3.svg";
import img4 from "./img/img4.svg";
import img5 from "./img/img5.svg";

const images = [img1, img2, img3, img4, img5];
const defautlValue = Math.floor(Math.random() * images.length);
const maxLength = images.length;
function QuestionIcon(props) {
  const { question, index } = props;

  return (
    <div className="question-icon-whole">
      <div className="question-icon">
        <img src={images[(defautlValue + index) % maxLength]}></img>
      </div>
    </div>
  );
}

export default QuestionIcon;
