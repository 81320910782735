import React from "react";

import MdHand from "react-ionicons/lib/MdHand";
import IosCube from "react-ionicons/lib/IosCube";
import IosCubeOutline from "react-ionicons/lib/IosCubeOutline";
import MdPaperPlane from "react-ionicons/lib/MdPaperPlane";
import MdArrowDropright from "react-ionicons/lib/MdArrowDropright";

import "./style.scss";
const ReviewGame = props => {
  const { lang, game } = props;
  const { numberOfQuestions, players, timeoutPerQuestion } = global.lang;

  return (
    <div>
      <div className="question-row">
        <div className="setting-body-item">
          <div className="setting-body-item-1st">
            <p>{numberOfQuestions[lang]}</p>
          </div>
          <div className="setting-body-item-2st">
            <p>{game.numberOfQuestions}</p>
          </div>
        </div>
      </div>
      <div className="question-row">
        <div className="setting-body-item">
          <div className="setting-body-item-1st">
            <p>{players[lang]}</p>
          </div>
          <div className="setting-body-item-2st">
            {game.players.map(value => (
              <div>
                <li>{value}</li>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="question-row">
        <div className="setting-body-item">
          <div className="setting-body-item-1st">
            <p>{timeoutPerQuestion[lang]}</p>
          </div>
          <div className="setting-body-item-2st">
            <p>{game.timeout}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReviewGame;
