import React, { useState } from "react";
import "./style.scss";

function TimerBar(props) {
  const { cb, time } = props;

  return (
    <div className="timer">
      <div className="question-time-view">
        <div
          className="question-time-bar"
          style={
            props.selected ? { animation: `makeFull ${time}s linear 1` } : {}
          }
        />
      </div>
    </div>
  );
}

export default TimerBar;
