import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import getGame from "../../helpers/game/getGame";
import LoadingPanel from "../../components/loadingPanel";
import Alert from "react-bootstrap/Alert";
import Header from "../../components/header";
import getSocket from ".././../helpers/socket/index";
import setQuestion from "../../helpers/game/setQuestion";
import Background from "../../components/background";
import joinGame from "../../helpers/socket/joinGame";
import GameComponent from "./gameComponent";

let socket;

function Game(props) {
  const [scoreBoard, setScoreBoard] = useState(false);
  const [gameAvalible, setGameAvalible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const username = props.cookies.get("username");
  const token = props.cookies.get("token");

  if (!username || !token) {
    window.location.href = "/login";
  }

  useEffect(() => {
    if (!gameAvalible && props.game.currentQuestion >= 0) {
      setGameAvalible(true);
    }
  }, [props.game]);

  const {
    lang,
    match: { params }
  } = props;

  const id = params.id;

  const updateGame = function(event) {
    console.log(event);
    if (!event.data) return;
    const data = JSON.parse(event.data);
    if (!data.map) return;
    data.map(value => props.updateGame(value));
  };

  if (loading) {
    getGame(username, token, id).then(value => {
      if (value.statusCode == 200) {
        props.setGame(value.game);

        socket = getSocket(
          updateGame,
          () => {
            joinGame(username, token, value.game._id);
            setQuestion(username, token, -1, value.game._id);
            setTimeout(
              () => setQuestion(username, token, 0, value.game._id),
              3000
            );
          },
          value.game._id
        );
      } else {
        setError("Bad sign in");
      }
      setLoading(false);
    });
  }

  const AlertComponent = () => (
    <Alert
      key="danger"
      variant="danger"
      style={{
        position: "absolute",
        width: "100%",
        textAlign: "center",
        zIndex: 100
      }}
      variant="danger"
      onClose={() => setError()}
      dismissible
    >
      <p>{error}</p>
    </Alert>
  );

  const LoadingPanelComponent = () => (
    <div className="loading-screen">
      <div className="lobby-spinner">
        <LoadingPanel width="40" height="40" />
      </div>
    </div>
  );
  const WaitingPanelComponent = () => (
    <div className="loading-screen">
      <div className="lobby-spinner">
        <LoadingPanel width="40" height="40" />
      </div>
    </div>
  );

  return (
    <div className="home">
      <Background />
      <Header value="QTime" />
      <div className="panel">
        {error ? <AlertComponent /> : <div />}
        {loading ? (
          <LoadingPanelComponent />
        ) : gameAvalible ? (
          scoreBoard ? (
            <h1>TEST</h1>
          ) : (
            <div>
              <GameComponent
                {...props}
                username={username}
                token={token}
                gameID={id}
              />
            </div>
          )
        ) : (
          <WaitingPanelComponent />
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    game: state.game,
    lang: state.lang
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setGame: item => {
      dispatch({ type: "SET_GAME", payload: item });
    },
    updateGame: item => {
      dispatch({ type: "CHANGE_GAME", payload: item });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Game);
