import React from "react";
import "./style.scss";
import MdPerson from "react-ionicons/lib/MdPerson";
import IosBrush from "react-ionicons/lib/IosBrush";
import IosFlask from "react-ionicons/lib/IosFlask";
import IosSchool from "react-ionicons/lib/IosSchool";
import IosTrophy from "react-ionicons/lib/IosTrophy";

import logo from "./logo.svg";
const LabIcon = props => {
  return (
    <div className="logo" onClick={() => (window.location.href = "/home")}>
      <img className="logo-svg" src={logo} />
    </div>
  );
};
export default LabIcon;
