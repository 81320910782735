import React, { useState, useEffect } from "react";
import IosCreate from "react-ionicons/lib/IosCreate";
import IosContacts from "react-ionicons/lib/IosContacts";
import IosSettings from "react-ionicons/lib/IosSettings";
import MdInformationCircle from "react-ionicons/lib/MdInformationCircle";
import "./style.scss";
import Header from "../../components/header";
import { connect } from "react-redux";
import createGame from "../../helpers/game/createNew";
import getGame from "../../helpers/game/getGame";
import LoadingPanel from "../../components/loadingPanel";
import Alert from "react-bootstrap/Alert";
import GameView from "./gameView";
import Button from "react-bootstrap/Button";
import Logo from "../../components/logo";
import getSocket from ".././../helpers/socket/index";
import Background from "../../components/background";
import joinGame from "../../helpers/socket/joinGame";
import setStarted from "../../helpers/game/setStarted";
import getUser from "../../helpers/users/getUser";
let socket;

function Lobby(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const { create, invites, settings, aboutUs } = global.lang;
  const {
    lang,
    match: { params }
  } = props;

  const id = params.id;

  const username = props.cookies.get("username");
  const token = props.cookies.get("token");

  const updateGame = function(event) {
    if (!event.data) return;
    const data = JSON.parse(event.data);
    if (!data[0]) return;
    data.map(value => props.updateGame(value));
  };

  if (!username || !token) {
    window.location.href = "/login";
  }

  if (!props.user._id) {
    console.log(props.user);
    getUser(username, token).then(value => props.setUser(value.user));
  }
  if (!id) {
    createGame(username, token).then(value => {
      if (value.statusCode == 200) {
        window.location.href = "/lobby/" + value.gameID;
      } else {
      }
    });
  } else if (loading) {
    getGame(username, token, id).then(value => {
      if (value.statusCode == 200) {
        props.setGame(value.game);
        socket = getSocket(
          updateGame,
          () => joinGame(username, token, value.game._id),
          value.game._id
        );
      } else {
        setError("Bad sign in");
      }
      setLoading(false);
    });
  }

  const AlertComponent = () => (
    <Alert
      key="danger"
      variant="danger"
      style={{
        position: "absolute",
        width: "100%",
        textAlign: "center",
        zIndex: 100
      }}
      variant="danger"
      onClose={() => setError()}
      dismissible
      className="error"
    >
      <p>{error}</p>
    </Alert>
  );

  const LoadingPanelComponent = () => (
    <div className="loading-screen">
      <div className="lobby-spinner">
        <LoadingPanel width="400px" height="400px" />
      </div>
    </div>
  );
  console.log(username);
  return (
    <div className="home">
      <Background />
      <Header value="QTime" />
      <div className="panel">
        {error ? <AlertComponent /> : <div />}
        {loading ? (
          <LoadingPanelComponent />
        ) : (
          <div>
            <GameView
              socket={socket}
              {...props}
              username={username}
              token={token}
              gameID={id}
              setError={setError}
            />
          </div>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    game: state.game,
    lang: state.lang,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setGame: item => {
      dispatch({ type: "SET_GAME", payload: item });
    },
    updateGame: item => {
      dispatch({ type: "CHANGE_GAME", payload: item });
    },
    setUser: item => {
      dispatch({ type: "SET_USER", payload: item });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Lobby);
