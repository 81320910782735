import React from "react";
import "./style.scss";
import Header from "../../components/header";
import Background from "../../components/background";
import Form from "react-bootstrap/Form";
import Emoji from "../../components/emoji";
import { connect } from "react-redux";
import getUser from "../../helpers/users/getUser";
const index = props => {
  const { lang } = props;
  const { settings } = global.lang;

  const username = props.cookies.get("username");
  const token = props.cookies.get("token");

  if (!username || !token) {
    window.location.href = "/login";
  }

  if (!props.user._id) {
    console.log(props.user);
    getUser(username, token).then(value => props.setUser(value.user));
  }

  return (
    <div>
      <Background />
      <Header value={"QTime"} />
      <div>
        <div className="invites-container">
          <div className="score-board">
            <div className="score-board-title">
              <p>{settings[lang]}</p>
            </div>
            <div className="settings-settings">
              <Settings value="test" />
              <Settings value="test" />
              <Settings value="test" />
              <Settings value="test" />
              <Settings value="test" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function Settings({ value }) {
  return (
    <div className="invites-board">
      <div className="invites-board-icon">
        <Emoji symbol="⚙️" />
      </div>
      <div className="invites-board-name">
        <Form.Label>{value}</Form.Label>
      </div>
      <div className="settings-board-value">
        <Form.Check />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    lang: state.lang,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setUser: item => {
      dispatch({ type: "SET_USER", payload: item });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);
