import React, { useState, useEffect } from "react";
import Scroll from "../../../components/scrollScreen";
import getQuestion from "../../../helpers/game/getQuestion";
import QuestionComponent from "./singleQuestionView";
import setQuestion from "../../../helpers/game/setQuestion";

import LoadingPanel from "../../../components/loadingPanel";
import "./style.scss";
import { setTimeout } from "timers";
import timer from "../../../helpers/utils/timer";
import ScoreBoard from "./scoreBoard";
let uname, pword;
let socket;
let question;
let previouseQuestion;
let username;
let correct;
function GameComponent(props) {
  const { next } = global.lang;
  const { game, username, token, gameID } = props;
  const [complete, setComplete] = useState(false);
  const scoreBoardOnly = false;
  let index = game.currentQuestion;

  const [array, setArray] = useState([]);

  useEffect(() => {
    getQuestion(username, token, gameID).then(value => {
      const arr = value.question.map((v, index) => (
        <QuestionComponent
          index={index}
          question={v}
          game={game}
          username={username}
          token={token}
          gameID={gameID}
          correct={correct}
        />
      ));

      if (scoreBoardOnly) {
        setArray([
          <ScoreBoard
            game={game}
            username={username}
            token={token}
            gameID={gameID}
          />
        ]);
      } else {
        setArray([
          ...arr,
          <ScoreBoard
            game={game}
            username={username}
            token={token}
            gameID={gameID}
          />
        ]);
        timer(() => {
          if (index < game.numberOfQuestions && game.admin.includes(username))
            setQuestion(username, token, ++index, gameID);
        }, game.timeout * 1000 + 5000);
      }
    });
  }, []);

  return (
    <div className="game">
      <div className="game-panel">
        <div className="game-screen">
          <Scroll array={array} width={"40"} height={"60"} index={index} />
        </div>
      </div>
    </div>
  );
}

export default GameComponent;
