import React, { useState, useEffect } from "react";
import sittingRobot from "./sitting-robot.svg";
import manufacturing from "./production.svg";
import "./style.scss";

const RobotIcon = () => {
  const [pos, setPos] = useState({});
  useEffect(() => {
    (function() {
      document.onmousemove = handleMouseMove;
      function handleMouseMove(event) {
        var eventDoc, doc, body;

        event = event || window.event; // IE-ism

        // If pageX/Y aren't available and clientX/Y are,
        // calculate pageX/Y - logic taken from jQuery.
        // (This is to support old IE)
        if (event.pageX == null && event.clientX != null) {
          eventDoc = (event.target && event.target.ownerDocument) || document;
          doc = eventDoc.documentElement;
          body = eventDoc.body;

          event.pageX =
            event.clientX +
            ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) -
            ((doc && doc.clientLeft) || (body && body.clientLeft) || 0);
          event.pageY =
            event.clientY +
            ((doc && doc.scrollTop) || (body && body.scrollTop) || 0) -
            ((doc && doc.clientTop) || (body && body.clientTop) || 0);
        }
        setPos({
          x: event.pageX / window.innerWidth,
          y: event.pageY / window.innerHeight
        });
        console.log(event.pageX / window.innerWidth);
        // Use event.pageX / event.pageY here
      }
    })();
  }, []);

  return (
    <div className="sitting-robot">
      <div className="sitting-robot-container">
        <img src={sittingRobot} />

        <div
          className="sitting-robot-eyes"
          style={{
            marginLeft: `${-0.3 + 0.75 * pos.x}vh`,
            marginTop: `${-0.3 + 0.75 * pos.y}vh`
          }}
        >
          <div className="sitting-robot-eye1" />
          <div className="sitting-robot-eye2" />
        </div>
      </div>
    </div>
  );
};
export default RobotIcon;
